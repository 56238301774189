import { type Product, isProduct } from '~/types/models/product'
import type { ProductListBlock } from '~/types/storyblok/product-list-block'
import assignCategories from '~/utils/products/assignCategories'

interface Payload {
  uuid: string
  widgetId?: string
  productBlocks: Ref<ProductListBlock[]>
}

export function useProductListBlock(payload: Payload) {
  const { widgetId, productBlocks, uuid } = payload

  // This handles Category and XO Tag fields in Storyblok
  const pageProductVariablesBasedOnOneXoTag = computed(() => {
    const variableValues = productBlocks.value.map((b) => {
      if (b.categoryTag)
        return b.categoryTag

      if (b.category)
        return `category-${b.category}`

      return 'default'
    })

    return assignCategories(variableValues)
  })

  // This handles XO Variables field in Storyblok
  const pageVariablesBasedOnWidgetVariablesInput = computed<Record<string, string>>(() => {
    return productBlocks.value.reduce((acc, block) => {
      const variables = Object.fromEntries<string>(block.widgetVariables || new Map())

      return { ...acc, ...variables }
    }, {} as Record<string, string>)
  })

  const variables = computed(() => {
    return { ...pageVariablesBasedOnWidgetVariablesInput.value, ...pageProductVariablesBasedOnOneXoTag.value }
  })

  const { data, status } = useLazyAsyncData(
    `products-${uuid}`,
    async () => {
      if (!productBlocks.value.length || !widgetId)
        return Promise.resolve(undefined)

      const { $products } = useNuxtApp()

      return $products.fetchProducts({
        widgetId,
        variables: variables.value,
      })
    },
    {
      deep: false,
    },
  )

  const productsDistributionsMap = computed(() => {
    const map = new Map<string, Product[]>()
    let index = 0

    // Assign the desired amount of distributions to each block
    productBlocks.value.forEach((block) => {
      const distributionsCountInBlock = Math.max(block.distributionsCount || 1, 1)
      const range = Array.from({ length: distributionsCountInBlock }, (_, i) => i)
      const products = range.reduce((acc, i) => {
        const distribution = data.value?.items?.[index + i]?.filter(isProduct) || []

        return [...acc, ...distribution]
      }, [] as Product[])

      map.set(block.uid, products)

      index += distributionsCountInBlock
    })

    return map
  })

  onMounted(() => {
    const nuxtApp = useNuxtApp()

    if (data.value?.id && nuxtApp.isHydrating)
      useRootStore().addEarlyBirdRecord(data.value.id)

    if (widgetId)
      useSegment().setSegmentVariables(widgetId, variables.value)
  })

  const pending = computed(() => {
    return status.value === 'pending'
  })

  return {
    data,
    pending,
    productsDistributionsMap,
  }
}
