export default function assignCategories(variables: string[]) {
  const categories = new Map<string, string>()

  variables.forEach((value, i) => {
    if (value === 'default')
      return

    const variableKey = `$category${i + 1}`
    categories.set(variableKey, value)
  })

  return Object.fromEntries(categories)
}

if (import.meta.vitest) {
  const { it, expect, describe } = import.meta.vitest
  describe('utils/products/assignCategories', () => {
    it('should assign categories', () => {
      const variables
        = ['category-77', 'default', 'category-666']

      const categories = {
        $category1: 'category-77',
        $category3: 'category-666',
      }

      expect(assignCategories(variables)).toEqual(categories)

      variables[0] = 'socks'
      categories.$category1 = 'socks'
      expect(assignCategories(variables)).toEqual(categories)
    })
  })
}
